<template>
  <atoms-menu-item
    padding="none"
    theme="global-navbar"
    class="menu-other max-w-12"
    inner-wrapper-class="notification-btn"
    @click="handleNotificationClick"
  >
    <div class="relative min-w-12 py-0.5 flex justify-center">
      <NuxtImg
        src="/icons/bell.svg"
        alt="notifications"
        width="16"
        height="20"
      />
      <span
        class="absolute top-0 left-6 bg-red-600 text-white text-[8px] rounded-full min-w-3 h-3 flex items-center justify-center px-1 py-0.5"
        :class="{
          'hidden': !notificationCount
        }"
      >
        {{ notificationCount > 99 ? '99+' : notificationCount }}
      </span>
    </div>
  </atoms-menu-item>
</template>

<script setup>
defineOptions({
  name: 'MoleculesNavNotifications'
})

const brazeNotificationStore = useBrazeNotificationStore()
const { notificationCount } = storeToRefs(brazeNotificationStore)
const { $bus, $tracking } = useNuxtApp()

// Handle notifications on icon click
const handleNotificationClick = event => {
  $tracking.click(event, '', { 
    elementId: 'notification-toggle-btn',
    elementInnerText: 'notification-bell-icon'
  })
  $bus.emit('toggleNotifications', {})
}

onMounted(() => {
  brazeNotificationStore.initializeBraze()
  brazeNotificationStore.pollContentCards()
})

onUnmounted(() => {
  brazeNotificationStore.clearPolling()
})
</script>
