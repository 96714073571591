<template>
  <molecules-modal
    ref="mhAuthModal"
    size="small"
    class="text-dark !z-[999]"
    :card-size="devices.isMobile.value ? 'md' : 'lg'"
    :card-class="`${cardClasses} !max-w-[26.875rem] !w-full !m-0`"
    close-button-size="standard"
    close-icon-class="!right-1.5 w-6 h-6 md:w-8 md:h-8"
    :close-icon-circle="true"
    :overlay-clickable="false"
    :close-action="resetAuthForm"
    full-page
  >
    <OrganismsAuthenticationPostSale
      v-if="psRegistration"
      class="!mt-0"
      :user-information="userInformation" />
    <OrganismsAuthentication
      v-else
      ref="authModule"
      auth-route="login"
      :compact="false"
      @ps-registration="initPsRegistration"
    />
  </molecules-modal>
</template>
<script setup>
const { $bus } = useNuxtApp()

const { devices } = deviceChecker()

const userStore = useUserStore()

defineOptions({
  name: 'OrganismsMemberHubModalAuthentication'
})

const mhAuthModal = ref()
const authModule = ref()

$bus.off('mh-auth')
$bus.on('mh-auth', payload => {
  setAuthModuleParams(payload)
  mhAuthModal.value?.toggle()
})

$bus.on('authenticated', () => {
  mhAuthModal.value?.toggle(false)
})

const cardClasses = computed(() => {
  if (devices.isMobile.value) {
    return '!rounded-none top-0 !fixed'
  }
  return 'rounded-2.5xl'
})

const setAuthModuleParams = payload => {
  userStore.authModuleParams = payload
}

const resetAuthForm = () => {
  if(authModule.value) authModule.value.$emit('reset-form')
  else if(psRegistration.value) {
    psRegistration.value = false
    userInformation.value = { name: '', email:'' }
  }
}

const psRegistration = ref(false)
const userInformation = ref({
  name: '',
  email: ''
})
/*
 * Initializes the post-sale registration process.
 * This function sets the `psRegistration` flag to true, indicating that the post-sale registration process has started.
 * It updates the `userInformation` object with the user's name and email from the provided payload.
 * Finally, it sets `emptyPage` to false, making the page content visible.
 *
 * @param {Object} payload - The data object containing the user's information.
 * @param {string} payload.name - The name of the user.
 * @param {string} payload.email - The email of the user.
 */
function initPsRegistration (payload) {
  psRegistration.value = true
  userInformation.value = { name: payload?.name, email:payload?.email }
}
</script>
