// stores/notifications.js
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useUserStore } from '@/stores/user'

export const useBrazeNotificationStore = defineStore('brazeNotificationStore', () => {
  const notificationCount = ref(0) // For holding the notification count
  const pollCount = ref(0) // For polling attempts
  const intervalId = ref(null) // To store the polling interval

  const MAX_ATTEMPTS = 6 // Maximum polling attempts
  const POLL_INTERVAL = 5000 // 5 seconds polling interval for Braze window.braze

  const userStore = useUserStore() // Accessing user data from another store
  
  // 1. Helper to check window and Braze availability
  const isBrazeAvailable = () => typeof window !== 'undefined' && window.braze

  // 2. WAIT until Braze (on `window`) is loaded and ready before performing actions.
  const waitForBraze = () => {
    if (isBrazeAvailable()) {
      return Promise.resolve(window.braze)
    }
    return new Promise(resolve => {
      const interval = setInterval(() => {
        if (isBrazeAvailable()) {
          clearInterval(interval)
          resolve(window.braze)
        }
      }, 500) // Poll every 500ms for Braze to load
    })
  }

  // 3. Initialize Braze (like changing user and opening the session after Braze is loaded)
  const initializeBraze = async () => {
    // eslint-disable-next-line no-console
    console.log('Initializing Braze notifications')
    const userID = userStore.getUserProfile?.hash_id

    try {
      const braze = await waitForBraze() // Ensure Braze is fully loaded

      if (userID && userID !== braze.getUser()?.getUserId()) {
        braze.changeUser(userID)
        braze.openSession()
      }

      // Subscribe to content card updates
      braze.subscribeToContentCardsUpdates(() => {
        notificationCount.value = braze.getCachedContentCards().getUnviewedCardCount()
      })

    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error during Braze initialization:', error)
    }
  }

  // 4. Polling for content card refresh
  const pollContentCards = async () => {
    try {
      const braze = await waitForBraze() // Await Braze before polling

      const poll = () => {
        if (pollCount.value >= MAX_ATTEMPTS) {
          clearInterval(intervalId.value) // Stop polling after max attempts
          intervalId.value = null
          pollCount.value = 0
          return
        }

        // eslint-disable-next-line no-console
        console.log('Polling for content cards:', pollCount.value)
        braze.requestContentCardsRefresh() // Ask Braze to refresh notifications
        pollCount.value++
      }

      if (!intervalId.value) {
        intervalId.value = setInterval(poll, POLL_INTERVAL)
      }

    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error during Braze polling:', error)
    }
  }

  // 6. Clear polling (when component is unmounted or in teardown phase)
  const clearPolling = () => {
    if (intervalId.value) {
      clearInterval(intervalId.value)
      intervalId.value = null
      pollCount.value = 0
    }
  }

  return {
    notificationCount,
    initializeBraze,
    pollContentCards,
    clearPolling,
    waitForBraze
  }
})