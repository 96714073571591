import memberhubConstants from '~/constants/memberhub.constants'
export function useOneTap () {
  const layoutStore = useLayoutsStore()
  const userStore = useUserStore()
  const route = useRoute()
  const { googleClientId } = useRuntimeConfig().public
  const { $bus, $toast, $tracking } = useNuxtApp()

  const googleAttempt = ref(0)
  const userLoggedIn = computed(() => {
    return userStore.isUserLoggedIn
  })

  onMounted(() => {
    nextTick().then(() => {
      oneTapHandler()
      loggedInListener()
    })
  })

  /*
   * One tap handler & filtering conditions
   */
  function oneTapHandler () {
    /*
     | =========================================================
     | CONDITIONS SUMMARY
     | 1) User shouldn't be logged-in
     | 2) Current route should be in enabled route(s) list
     | =========================================================
     */
    if (!userLoggedIn.value && (memberhubConstants.ONE_TAP_ENABLED_ROUTES.includes(trailingSlash(route.path)) || memberhubConstants.ONE_TAP_ENABLED_ROUTE_SET.some(routeTmp => !!route.path.includes(routeTmp)))) {
      googleOneTap()
    } else {
      dismissOneTap()
    }
  }
  /*
   * Google one tap plugin initialization
   */
  function googleOneTap () {
    const link = document.createElement('script')
    link.src = 'https://accounts.google.com/gsi/client'
    document.getElementsByTagName('head')[0].appendChild(link)
    nextTick().then(() => {
      if (window.google?.accounts) {
        window.google.accounts.id.initialize({
          client_id: googleClientId,
          cancel_on_tap_outside: false,
          callback: listenOneTapCallback
        })
        nextTick().then(() => {
          promptOneTap()
        })
      } else if (!googleAttempt.value) {
        setTimeout(() => {
          googleAttempt.value++
          googleOneTap()
        }, 1000)
      }
    })
  }
  /*
   * One tap prompt handler
   */
  function promptOneTap () {
    nextTick().then(() => {
      if (window.google?.accounts) {
        window.google.accounts.id.prompt()
      }
    })
  }
  /*
   * Dismissing one tap prompt
   */
  function dismissOneTap () {
    if (window.google?.accounts) {
      window.google.accounts.id.cancel()
    }
  }
  /*
   * Logged in event listener
   */
  function loggedInListener () {
    $bus.off('logged-in')
    $bus.on('logged-in', () => {
      nextTick().then(() => {
        dismissOneTap()
      })
    })
  }
  /*
   * Auth callback listener
   * @param e
   */
  function listenOneTapCallback (e) {
    layoutStore.setPageLoader(true)
    nextTick().then(() => {
      $tracking.customEvent('One Tap Click', {})
      userStore.googleOneTapAuthInit({
        state: userStore.defaultAuthState,
        id_token: e.credential
      }).catch(() => {
        layoutStore.setPageLoader(false)
        $toast.error()
      })
    })
  }
}
