<template>
  <div class="z-[3] bg-white">
    <atoms-menu
      v-show="!showMenuDetail"
      class="justify-center border-b border-b-gray-400 bg-white"
      is-vertical-menu
    >
      <atoms-menu-item
        v-for="(item, itemKey) in mainMenu"
        :key="itemKey"
        :has-background-when-active="false"
        :arrow="false"
        :active="item.title === activeVertical"
        :to="
          'to' in item
            ? item.to
            : null
        "
        theme="default-full"
        padding="xxLarge"
        class="border-t border-t-gray-400"
        inner-wrapper-class="flex flex-col"
        is-vertical-menu
        @click="
          'to' in item
            ? null
            : handleCurrVertical(item.title)
        "
      >
        <div class="w-full flex">
          <span class="text-dark w-full text-left font-normal">
            {{ item.title }}
          </span>

          <IconsChevron
            direction="right"
            size="lg"
          />
        </div>
      </atoms-menu-item>
    </atoms-menu>

    <div
      v-if="activeVertical && showMenuDetail"
      class="bg-white w-full"
    >
      <div
        v-if="showBack"
        class="font-bold flex items-center px-4 py-5 border-t border-t-gray-20 border-b border-b-gray-20"
        @click="() => showMenuDetail = false"
      >
        <IconsChevron
          direction="left"
          size="lg"
          class="mr-4"
        />

        {{ activeVertical }}
      </div>

      <div
        v-if="currMenuItems?.firstColumn"
        class="border-b border-b-gray-20 bg-gray-300"
      >
        <atoms-collapse
          id="firstColumn"
          name="nav-menu-details"
          related
          open
        >
          <template #title>
            <strong>
              {{ currMenuItems?.firstColumn.title }}
            </strong>
          </template>

          <template #content>
            <atoms-menu
              class="bg-white border-t border-t-gray-20 space-y-2 px-8 py-4 flex-col"
              is-vertical-menu
            >
              <atoms-menu-item
                v-for="(item, itemKey) in menuItems?.firstColumn"
                :key="itemKey"
                :class="{
                  'underline': item?.underline
                }"
                :to="item.url"
                theme="rounded"
                padding="withImageSmall"
                class="nav-link"
                is-vertical-menu
              >
                <AtomsImage
                  v-if="item.image"
                  width="24"
                  height="24"
                  :alt="item.title.toLowerCase()"
                  :src="item.image"
                  class="max-h-24 mr-[1.125rem]"
                />

                <span class="text-dark font-normal">
                  {{ item.title }}
                </span>

                <IconsChevron
                  class="inline-block ml-6"
                  height="12"
                  width="17"
                />
              </atoms-menu-item>
            </atoms-menu>
          </template>
        </atoms-collapse>
      </div>

      <div
        v-if="currMenuItems?.secondColumn && currMenuItems?.secondColumn.title"
        class="border-b border-b-gray-20 bg-gray-300"
      >
        <atoms-collapse
          id="secondColumn"
          name="nav-menu-details"
          related
        >
          <template #title>
            <strong>
              {{ currMenuItems?.secondColumn.title }}
            </strong>
          </template>

          <template #content>
            <atoms-menu
              class="bg-white border-t border-t-gray-20 space-y-2 px-8 py-4 flex-col"
              is-vertical-menu
            >
              <atoms-menu-item
                v-for="(item, itemKey) in menuItems?.secondColumn"
                :key="itemKey"
                :class="{
                  'underline': item?.underline
                }"
                :to="item.url"
                theme="rounded"
                padding="withImageSmall"
                class="nav-link"
                is-vertical-menu
              >
                <AtomsImage
                  v-if="item.image"
                  width="24"
                  height="24"
                  :alt="item.title.toLowerCase()"
                  :src="item.image"
                  class="max-h-24 mr-[1.125rem]"
                />

                <span class="font-normal">
                  {{ item.title }}
                </span>

                <IconsChevron
                  class="inline-block ml-4"
                  height="12"
                  width="17"
                />
              </atoms-menu-item>
            </atoms-menu>
          </template>
        </atoms-collapse>
      </div>

      <div
        v-if="currMenuItems?.thirdColumn"
        class="border-b border-b-gray-20 bg-gray-300"
      >
        <atoms-collapse
          id="thirdColumn"
          name="nav-menu-details"
          related
        >
          <template #title>
            <strong>
              {{ currMenuItems?.thirdColumn.title }}
            </strong>
          </template>

          <template #content>
            <atoms-card
              v-for="(item, itemKey) in menuItems?.thirdColumn"
              :key="itemKey"
              shadow="none"
              rounded="none"
              size="none"
              class="w-full min-h-[5.5rem] first:border-t border-b border-gray-20 last:border-b-0"
            >
              <atoms-cta
                :to="item.url"
                size="extra small"
                theme="plain"
                class="w-full h-full min-h-inherit flex items-center px-8 py-2 font-semibold"
                :class="{
                  'uppercase underline justify-center': item.underline,
                  'justify-left': !item.underline
                }"
              >
                <NuxtImg
                  v-if="item.image"
                  width="72"
                  height="72"
                  :alt="item.title.toLowerCase()"
                  :src="item.image.split('?')[0] + '?w=72&h=72&fit=fill&r=8&fm=webp'"
                  class="max-h-72 mr-[1.125rem]"
                />

                <span
                  v-else-if="!item.underline"
                  class="h-[4.5rem] w-[4.5rem] mr-[1.125rem] rounded-md overflow-hidden relative bg-gray-200"
                >
                </span>

                <p
                  :class="{
                    'text-left': !item.underline, 'font-bold': item.underline
                  }"
                >
                  {{ item.title }}
                </p>

                <IconsChevron
                  v-if="item.underline"
                  class="inline-block ml-4"
                  height="12"
                  width="17"
                />
              </atoms-cta>
            </atoms-card>
          </template>
        </atoms-collapse>
      </div>

      <div class="bg-white border-b border-gray-20 px-4 py-5 w-full">
        Find out more about

        <a
          class="font-bold"
          :href="currMenuItems?.details?.productPage"
        >
          <span class="underline inline">
            {{ currMenuItems?.title }}
          </span>

          <NuxtImg
            src="/icons/arrow-line-right.svg"
            width="20"
            height="10"
            class="inline ml-1"
            alt="arrow"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import _isEmpty from 'underscore/cjs/isEmpty.js'
import _size from 'underscore/cjs/size.js'

import { useNavigationStore } from '@/stores/navigation'
const { featuredMenuItems } = useNavigationStore()

defineOptions({
  name: 'OrganismsNavMobileMenu'
})

const props = defineProps({
  menu: {
    type: Object,
    required: false,
    default: () => ({})
  },

  currMenu: {
    type: String,
    default: ''
  },

  ctaOnly: {
    type: Boolean,
    default: false
  }
})

// variables
const activeVertical = ref('')
const showMenuDetail = ref(false)

const mainMenu = computed(() => props.menu?.menu || [])
const mainMenuArr = computed(() => (!_isEmpty(mainMenu.value) && Object.keys(mainMenu.value)) || [])

const showBack = computed(() => (!_isEmpty(mainMenu.value) && _size(mainMenu.value) > 1))

const currMenuItems = computed(() => {
  const menu = mainMenu.value?.[activeVertical.value]

  return menu && !menu?.to
    ? menu
    : false
})

const menuItems = computed(() => {
  const currItems = currMenuItems.value

  const secondColumn = currItems?.secondColumn?.links
  const firstColumn = (() => {
    let links = currItems?.firstColumn?.links

    if (
      (!currItems?.secondColumn?.title ||
      currItems?.secondColumn?.title.trim().length === 0) &&
      !_isEmpty(secondColumn)
    ) {
      links = links.concat(secondColumn)
    }

    return links
  })()

  const thirdColumn = (() => {
    const links = currItems?.thirdColumn?.links ?? []
    const newLinks = featuredMenuItems?.[activeVertical.value] ?? []

    if (!_isEmpty(newLinks)) {
      return [
        ...newLinks,
        ...links
      ]
    }

    return links
  })()

  return {
    firstColumn,
    secondColumn,
    thirdColumn
  }
})

watch(() => props.currMenu, val => {
  if (!val) {
    activeVertical.value = ''
  } else {
    activeVertical.value = typeof mainMenuArr.value[0] !== 'undefined'
      ? mainMenuArr.value[0]
      : null

    /**
     * automatically activates menu details
     * if mainMenu list size is equal to 1
     */
    showMenuDetail.value = _size(mainMenu.value) === 1
  }
})

function handleCurrVertical (vertical) {
  activeVertical.value = vertical
  showMenuDetail.value = true
}
</script>
