<template>
  <div v-if="date && show">
    <atoms-alert
      :type="!comparisonPage ? 'info' : 'warning'"
      class="text-left md:text-center !text-xs/[1.125rem] md:!text-sm !px-4 md:!py-[0.625rem]"
      :class="{
        'md:border-y-0': !comparisonPage
      }"
      :rounded="false"
      full-width
    >
      <template v-if="!comparisonPage">
        Health premiums go up from {{ formattedDate.dayMonth }} so our experts are available later in the day to help you switch. Call us:

        {{ schedule }}

        <template v-if="scheduleWeekend">
          &
        </template>

        {{ scheduleWeekend }}.

        <nuxt-link
          id="rate-rise-tile-find-out-more"
          class="underline hover:cursor-pointer font-bold"
          to="https://compareclub.com.au/health-insurance/health-insurance-premium-increase/"
          target="_blank"
          @click="$event => $tracking.click($event)"
        >
          Find out more
        </nuxt-link>
      </template>

      <template v-else>
        Health insurance <strong>premiums for some funds will increase</strong> <br class="block md:hidden" />
        on {{ formattedDate.dayMonthYear }}.
        <strong
          class="underline inline-block cursor-pointer"
          @click="() => rateRiseModalRef.toggle(true)"
        >
          Click here
        </strong>
        to learn more
      </template>
    </atoms-alert>

    <molecules-modal
      ref="rateRiseModalRef"
      class="!items-start pt-[5.5rem] md:pt-[9rem]"
      card-class="max-w-[40.9375rem] py-6 px-[1.0625rem] !m-0"
      :overlay-clickable="false"
    >
      <p class="font-bold text-lg/[1.625rem] md:text-xl mb-4 md:mb-[1.0625rem]">
        Why do my health insurance premiums increase every year?
      </p>

      <div class="bg-white border border-gray-400 rounded-2xl py-3 px-2 md:pl-5 md:pr-3 text-sm md:text-base mb-4 md:mb-6">
        <div class="overflow-auto max-h-[22.25rem] md:max-h-[27.9375rem] flex flex-col gap-y-5 md:gap-y-7 pr-2 md:pr-3">
          <p>
            Put simply, private health insurance premiums go up to cover increases in healthcare costs in Australia, This is because:
          </p>

          <ul class="list-disc ml-4">
            <li>
              There's new treatments that cost insurers more to cover.
            </li>

            <li>
              Australia's aging population means there's more demand on the health service
            </li>

            <li>
              Hospitals, doctors and medical specialists increase their prices.
            </li>
          </ul>

          <p>
            The government approves health insurance premium increases each year. Theoretically this ensures insurers to stay competitive and offer their members a vide range of services and benefits.
          </p>

          <ul class="list-disc ml-4">
            <li>
              In 2024, the average increase will be 3.03%
            </li>

            <li>
              We estimate the average family will pay an extra $153 on their policy*
            </li>

            <li>
              We estimate the average couple will pay an extra $171 on their policy*
            </li>

            <li>
              We estimate the average single will pay $81 extra on their policy*
            </li>
          </ul>

          <p>
            Not every policy will go up by an average of 3.03%. Some will be higher and some will be lower. Always check any communication from your health fund.
          </p>

          <p>
            *Based on 183,027 policies sold by Compare Club between 2019 - 2023
          </p>
        </div>
      </div>

      <atoms-button
        theme="secondary"
        :size="devices.breakpoint('md') ? 'small' : 'medium'"
        class="mx-auto w-full md:w-auto"
        @click="rateRiseModalRef.toggle(false)"
      >
        CLOSE
      </atoms-button>
    </molecules-modal>

    <div
      v-if="showTimer"
      class="fixed pl-4 pr-[22px] md:pr-[20px] w-full flex justify-end pointer-events-none transition-all"
      :style="{
        bottom: component.position
      }"
    >
      <div
        class="bg-[linear-gradient(83.93deg,_#193560_-31.02%,_#3EC58F_126.9%)] max-w-[382px] sm:max-w-[275px] text-white pointer-events-auto flex flex-col xs:flex-row sm:flex-col gap-x-2 gap-y-1 sm:gap-y-2 relative xs:items-center sm:items-start"
        :class="{
          'rounded-full w-auto': toggleCountdown,
          'rounded-lg w-full pt-[0.875rem] pb-[0.625rem] px-4 sm:pr-6': !toggleCountdown,
        }"
      >
        <template v-if="!toggleCountdown">
          <img
            src="/icons/close-circle-light.svg"
            class="absolute top-1 right-1 cursor-pointer hover:opacity-70"
            alt="counter close"
            @click="toggleCountdown = !toggleCountdown"
          />

          <p class="text-[9px]/[12.28px] xs:text-2xs/[0.8525rem] text-center xs:text-left md:text-sm">
            Get in before the <strong>health insurance premium</strong> increase
            <br
              v-if="devices.breakpoint('xxs', 'max')"
              class="block xxs:hidden"
            />
            on {{ formattedDate.dayMonthYear }}
          </p>

          <ul class="flex justify-center items-center [&>div]:text-center">
            <li
              v-for="(time, timeKey) in countdown"
              :key="timeKey"
              class="text-center flex relative"
            >
              <div>
                <div :class="component.time">
                  {{ time }}
                </div>

                <div :class="component.timeLabel">
                  {{ timeKey }}
                </div>
              </div>

              <div
                v-if="timeKey !== 'Seconds'"
                :class="[
                  component.semicolon,
                  {
                    'mx-5 xs:mx-[6px] sm:mx-[8px]': timeKey === 'Days',
                    'ml-5 mr-4 xs:mx-[6px] sm:mx-[8px]': timeKey === 'Hour',
                    'ml-4 mr-[0.875rem] xs:ml-[3px] xs:mr-[4px] sm:ml-[5px] sm:mr-[8px]': timeKey === 'Minutes'
                  }
                ]"
              >
                :
              </div>
            </li>
          </ul>
        </template>

        <div
          v-else
          class="cursor-pointer p-3 hover:opacity-70"
          @click="toggleCountdown = !toggleCountdown"
        >
          <img src="/icons/clock-light.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'

import _isEmpty from 'underscore/cjs/isEmpty.js'

defineOptions({
  name: 'MoleculesRateRiseNudgeBar'
})

const props = defineProps({
  config: {
    type: [String, Object],
    default: () => ({})
  },

  show: {
    type: Boolean,
    default: false
  }
})

const { $tracking } = useNuxtApp()
const $route = useRoute()
const { devices } = deviceChecker()

const schedule = ref(VERTICALS[VERTICAL.HEALTH_INSURANCE].schedule)
const scheduleWeekend = ref(VERTICALS[VERTICAL.HEALTH_INSURANCE].schedule_weekend)

const rateRiseModalRef = ref()
const toggleCountdown = ref(false)
const countdown = ref({
  Days: '00',
  Hour: '00',
  Minutes: '00',
  Seconds: '00'
})

const comparisonPage = computed(() => (['health-insurance-quote-resume', 'health-insurance-quotes-results'].includes($route.name)))

const showTimer = computed(() => {
  if (comparisonPage.value) {
    return false
  }

  if (
    $route.name === 'health-insurance-quote-form-step' &&
    $route.params.step === 'step1' &&
    devices.breakpoint('lg', 'max')
  ) {
    return false
  }

  return true
})

const component = computed(() => {
  const time = 'text-2xl sm:text-3.5xl font-bold'
  const timeLabel = 'text-2xs/[0.8525rem] sm:text-xs/[1.125rem] -mt-[0.375rem] sm:-mt-[0.5rem]'
  const semicolon = 'text-2xl md:text-3.5xl font-bold relative -top-[0.0625rem]'

  const position = '80px'

  return {
    time,
    timeLabel,
    semicolon,
    position
  }
})

const date = computed(() => {
  const dates = []
  const config = !_isEmpty(props.config)
    ? parsify(props.config, {})
    : {}

  for (const key in config) {
    if (Object.prototype.hasOwnProperty.call(config, key)) {
      const item = config?.[key]

      if (item?.date) {
        dates.push(item.date)
      }
    }
  }

  if (_isEmpty(dates)) {
    return null
  }

  const tempDate = dates.sort((a, b) => dayjs(b).isBefore(dayjs(a)))?.[0]

  return dayjs(tempDate)
})

const formattedDate = computed(() => {
  return {
    dayMonth: (date.value && date.value.format('D MMMM')) || '',
    dayMonthYear: (date.value && date.value.format('D MMMM YYYY')) || ''
  }
})

function updateCountdown () {
  const interval = setInterval(() => {
    const now = new Date(dayjs()).getTime()
    const future = new Date(date.value).getTime()
    const diff = future - now

    const _days = Math.floor(diff / (1000 * 60 * 60 * 24)).toString()
    const _hour = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString()
    const _minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)).toString()
    const _seconds = Math.floor((diff % (1000 * 60)) / 1000).toString()

    countdown.value.Days = _days.length === 1
      ? `0${_days}`
      : _days
    countdown.value.Hour = _hour.length === 1
      ? `0${_hour}`
      : _hour
    countdown.value.Minutes = _minutes.length === 1
      ? `0${_minutes}`
      : _minutes
    countdown.value.Seconds = _seconds.length === 1
      ? `0${_seconds}`
      : _seconds

    if (diff < 0) {
      clearInterval(interval)

      countdown.value.Days = '00'
      countdown.value.Hour = '00'
      countdown.value.Minutes = '00'
      countdown.value.Seconds = '00'
    }
  }, 1000)
}

onMounted(() => {
  updateCountdown()
})
</script>
