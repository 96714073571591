import _isEmpty from 'underscore/cjs/isEmpty.js'

const getters = {
  featuredMenuItems (state) {
    if (_isEmpty(state.featuredMenu)) {
      return []
    }

    const menu = JSON.parse(JSON.stringify(state.featuredMenu))

    for (const key in menu) {
      if (Object.hasOwnProperty.call(menu, key)) {
        const list = menu[key]

        list.forEach(item => {
          const urlLastChar = item.url.charAt(item.url.length - 1)

          if (urlLastChar !== '/') {
            item.url = `${item.url}/`
          }
        })
      }
    }

    return menu
  }
}

export default getters
