const state = () => ({
  featuredMenu: {
    'Overseas Visitor Health Cover': [],
    'Health Insurance': [],
    'Life Insurance': [],
    'Home Loans': [],
    'Credit Cards': [],
    Energy: [],
    'Car Loans': [],
    'Expert Analysis': []
  }
})

export default state
