<template>
  <div
    class="h-full justify-center"
    @mouseover="devices.isDesktop.value ? showProfileDropdown=true : null"
    @mouseleave="showProfileDropdown=false"
  >
    <div
      class="flex items-center justify-center w-full h-full px-2 xs:px-3"
      :class="{
        'bg-gray-200': showProfileDropdown,
        'bg-white': !showProfileDropdown
      }"
      @click="toggleDropdown"
    >
      <NuxtImg
        v-if="userDetails.picture"
        class="rounded-full w-6 h-6"
        :alt="userDetails.given_name"
        :src="userDetails.picture"
        width="32"
        height="32"
      />
      <p
        v-if="!iconOnly"
        class="text-sm text-dark font-bold ml-2"
      >
        {{ userDetails.given_name }}
      </p>

      <span v-if="!iconOnly">
        <IconsChevron
          class="ml-2 w-4 h-4 relative top-[0.0625rem]"
          type="thin"
          :direction="showProfileDropdown ? 'top': 'bottom'"
        />
      </span>
    </div>

    <div
      v-show="showProfileDropdown"
      class="absolute bg-gray-10 rounded-b-md w-max right-0 top-16 shadow-md min-w-[11.5rem]"
      @click="handleClose"
    >
      <ul>
        <li v-for="item in menuItems" :key="item.id">
          <NuxtLink
            :id="item.id"
            class="flex items-center w-full text-dark text-sm py-3 px-5 font-normal hover:font-bold"
            :to="item.to"
            :target="item.target || '_self'"
          >
            <NuxtImg
              :width="16"
              :height="16"
              :src="item.icon"
              class="mr-2" />
            {{ item.label }}
          </NuxtLink>
        </li>

        <li>
          <NuxtLink
            id="auth_logout_btn"
            class="flex items-center w-full text-dark text-sm py-3 px-5 font-normal hover:font-bold"
            type="button"
            @click="handleLogout"
          >
            <NuxtImg
              width="16"
              height="16"
              class="mr-3"
              src="/icons/power-off-dark.svg"
              alt="Logout"
            />
            Logout
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
defineOptions({
  name: 'MoleculesNavProfile'
})
const userStore = useUserStore()
const appStore = useLayoutsStore()
const router = useRouter()
const { devices } = deviceChecker()

const menuItems = ref([
  {
    id: 'dashboard_nav_btn',
    label: 'Dashboard',
    to: { name: 'dashboard' },
    icon: '/icons/home-outline.svg'
  },
  {
    id: 'account_settings_nav_btn',
    label: 'Account Settings',
    to: '/dashboard/account-settings/',
    icon: '/icons/settings-outline.svg'
  },
  {
    id: 'support_nav_btn',
    label: 'Support',
    to: 'https://support.compareclub.com.au/en/collections/9254046-club-account-management',
    icon: '/icons/support.svg',
    target: '_blank'
  }
])

defineProps({
  iconOnly: {
    type: Boolean,
    default: false
  }
})

onMounted(() => {
  nextTick().then(() => {
    /*
     | ======================================================
     | Listener for global logout events
     | Specially handles logout events from external packages
     | ======================================================
     */
    window?.document?.getElementById('app-main')?.addEventListener('logout', () => {
      handleLogout()
    })
  })
})

/*
 * Auth User details
 * @type {ComputedRef<*>}
 */
const userDetails = computed(() => userStore.getUserProfile)

const showProfileDropdown = ref(false)

const toggleDropdown = () => {
  showProfileDropdown.value = !showProfileDropdown.value
}

// functions
const handleClose = async () => {
  await wait(200)
  showProfileDropdown.value = false
}

/*
 * Logout handler
 */
function handleLogout () {
  appStore.setPageLoader(true)
  nextTick().then(() => {
    userStore.logout()
      .finally(() => {
        router.push('/login/')
        appStore.setPageLoader(false)
      })
  })
}
</script>

<style scoped style="scss">
.router-link-active {
  @apply bg-gray-200 font-bold;
}
a:hover {
  @apply bg-gray-300 font-bold;
}
</style>
